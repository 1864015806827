import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

class AboutKalotiPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            banner: 0
        };
      }
      playVideo(event) {
        const id = event.target.getAttribute('data-vidid') - 0
        document.getElementById("venners_vcover").style.display = 'none'
        document.getElementById('js--' + id).src += "&autoplay=1";
      }
    render() {
        return(
  <Layout>
    <SEO title="Dental Veneers – Porcelain Veneers Treatment | Toronto Cosmetic Dentist" 
    description="Porcelain Veneers are the ideal solution for anyone looking to improve their smile. The Smile Stylist offers ultra thin, long lasting and natural looking veneers." 
    keywords="porcelain veneers" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="veneers hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Porcelain Veneers</h1>
            <h2>It can be a good option for the beautiful smile you're looking for</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p className="lead">Porcelain veneers are thin pieces of porcelain used to recreate the natural look of teeth, while also providing strength and resilience comparable to natural tooth enamel.</p>
            <p>It is often the material of choice for those looking to make slight position alterations, or to change tooth shape, size, and/or color.</p>
            <div className="veneer-page_veneers__cover_outer">
              <iframe className="veneers__video" id="js--1"   src="https://www.youtube.com/embed/ImV5ByMuZS4?rel=0&amp;controls=1&amp&amp;showinfo=0&amp;modestbranding=0&enablejsapi=1" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen frameBorder="0" gesture="media" allow="encrypted-media" allowFullScreen></iframe>
              <div data-vidid="1" onClick={this.playVideo} id="venners_vcover"></div>
            </div>
            <h2>
              <a title="Porcelain Veneers" href="/porcelain-veneers">Porcelain Veneers Consultation</a>
            </h2>
            <p>As an active member American Academy Of Cosmetic Dentistry Dr. Kaloti is among the elite group of dentists that have committed countless hours for mastering the art and science of cosmetic dentistry. Asking him about veneers is the natural first step in determining if veneers are the right option for you, or if there are alternate solutions available. Voicing your aesthetic concerns about what you want corrected is critical for a successful result. Once you've spent time clearly identifying what cosmetic improvements you want to accomplish, a consultation with Dr Kaloti will help guide you into the treatment options available to you. Our <a href="/virtual-smile-makeover">Virtual Smile Makeover</a> page will offer you a complimentary and exciting glimpse to what's possible for your new Smile Stylist Smile!
            </p>
            <p>You'll often hear people say that celebrities have veneers and this may seem like the best way to replicate picture-perfect teeth, but each mouth is different and veneers need to be carefully researched.</p>
            <p>In our practice, we usually start with a smile design session to determine what steps are necessary to achieve the smile you desire. In addition, your Smile Stylist may create a diagnostic mock-up that will allow you to "try on" veneers and other procedures to see if the final result is actually what you're looking for before an treatment commences!</p>
            <p>We also show you a photo of how your new smile will look. We call this our <a href="/virtual-smile-makeover">Virtual Smile Makeover</a>.</p>
            <p>Deciding that porcelain veneers will create the look you want is only one step in the process. There is much more to learn before proceeding further.</p>
            <h2>The Hows and Whys of Porcelain Veneers</h2>
            <p>Porcelain laminate veneers consist of a compilation of several thin ceramic layers which replace original tooth enamel, and an adhesive layer. To apply a veneer, a very small amount of the original tooth enamel must be removed, usually less than a millimetre. This is essential as it creates room for the porcelain veneer to fit within the mouth and most accurately restore natural tooth function while creating an even better appearance than the original tooth.</p>
            <p>The bond between original tooth and porcelain veneer is critical as it not only provides the esthetic perfection desired, but also a strong bond which is essential for correct veneer function. Light-sensitive resin is placed between the original tooth and the veneer and then hardened using a special curing light.</p>
            <p>Porcelain veneers are a very successful option in many situations where the original tooth has developed poor colour, shape, and contours. It is also a good choice for fractured teeth, gaps between teeth, and in some situations where the tooth position is compromised and there are minor bite-related problems. For some people, superficial stains do not respond well to tooth whitening or bleaching. In these situations, a porcelain veneer may be the best option.</p>
            <h2>Minimal Prep or "No-Prep" Veneers</h2>
            <p>Some patients are looking for an alternative to traditional dental veneers or bonding, and while this treatment option is not appropriate for everyone, Dr Kaloti achieves amazing results with this option for his patients.</p>
            <p>Just as with porcelain veneers, "no-prep" or minimal preparation veneers so called because they typically don't require us to remove as much tooth materialare bonded to the front surface of your teeth. Often, the placement of no-prep veneers can be done more quickly and with less discomfort than traditional veneers.</p>
            <p>Your AACD member dentist will let you know if you are a good candidate for minimal preparation or "no-prep" veneers and if this option makes a sensible treatment plan.</p>
            <h2>The Benefits of Veneers</h2>
            <p>Since veneers are individually sculpted for each patient, it is nearly impossible to tell the difference between a veneer and a natural tooth. Unlike natural teeth, custom-made veneers resist coffee and tea stains, and cigarette smoke because they are made of high-tech materials.</p>
            <p>With veneers as opposed to crowns your natural teeth remain largely intact with only a minimal amount being altered to fit the veneer.</p>
            <p>For teeth that resist whitening, veneers can make even the darkest teeth appear bright white.</p>
            <p>We may also recommend veneers to quickly fix minor twists, overlaps, and small gaps.</p>
            <h2>Potential Veneer Downsides</h2>
            <p>Because a portion of the original tooth enamel is reduced, a veneer is not considered a reversible treatment. Although adjustments and even new veneers can be made, you can never reliably return to the original condition of the tooth.</p>
            <p>Creating porcelain veneers requires some laboratory time, so expect at least a week before they're ready to be applied.</p>
            <p>After the porcelain veneers are attached you will probably have some sensitivity to hot and cold temperatures due to the removal of that thin layer of enamel. This typically disappears within a few days. In a healthy mouth properly treated with porcelain veneersand where destructive forces are minimized or eliminated patient should be able to use porcelain veneers like his or her own teeth. Although they're very strong, veneers are also brittle. You should avoid the same excessive stresses you would avoid with non-veneered teeth: don't bite your fingernails, chew ice, or open beer bottles with your veneers!</p>
            <h2>Maintenace of a Porcelain Veneer</h2>
            <p>Maintaining porcelain veneers is actually quite simple: Treat them as you would your original teeth, with routine brushing and flossing. We suggest using non-abrasive fluoride toothpaste.</p>
            <p>One week after your veneers are placed, you will be required to return to the office for a follow-up visit and evaluation so we can see how your mouth is reacting to the veneers. Even if you feel the veneers are a success, this appointment is vital to your future oral health.</p>
            <p>If you have a habit of grinding or clenching your teeth, we may fit you with a nighttime bite guard so you do not damage your veneers.</p>
            <h2>5 Year Warranty</h2>
            <p>We want you to have peace of mind! While your veneers are typically a predictable and long-term cosmetic treatment option, occasionally things happen. We stand behind our work. If anything happens to a veneer within the first 5 years, we will take care of it for you at no cost to you! As long as you return for regular professional maintenance we have you and your smile covered!</p>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.4s">
            <p>
              <img src={ require("../images/desktop/services/porcelain-veneers-before-after.png" )} className="img-responsive" alt="Porcelain Veneers Before and After" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Caroline Before After.png" )} className="img-responsive" alt="Before and After Porcelain Veneers" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/ba-gum.jpg" )} title="Gum Lift + Porcelain Veneers" alt="Gums Lifting Before and After " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/ba-veneers1.jpg" )} title="Gum Lift + Porcelain Veneers" alt="Original Tooth and Porcelain Veneer " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Porcelain Veneers 3.png" )} title="Porcelain Veneers" alt="Porcelain Veneers" className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Implants Porcelain Veneers.png" )} title="Implants + Porcelain Veneers" alt="Porcelain Veneers" className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Porcelain Veneers 5.png" )} title="Porcelain Veneers" alt='"No-Prep" Veneers' className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Porcelain Veneers 6.png" )} title="Porcelain Veneers" alt='"No-Prep" Veneers Toronto' className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 7.png" )} title="Porcelain Veneers" alt="Downsides Potential Veneers " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 9.png" )} title="Porcelain Veneers" alt="Maintenace of a Porcelain Veneer" className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 10.png" )} title="Porcelain Veneers" alt="Complete Smile Makeover " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 11.png" )} title="Porcelain Veneers" alt="Veneer Teeth Before and After " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 12.png" )} title="Porcelain Veneers" alt="Smile Makeover Then and Now" className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 13.png" )} title="Porcelain Veneers" alt="Porcelain Veneers Then and Now " className="img-responsive" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/Before After Veneers 14.png" )} title="Porcelain Veneers" alt="Pretty Smile With Veneers" className="img-responsive" />
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)
        }
        }

export default AboutKalotiPage